<template>
	<div class="form-check field-checkbox" :class="classes">
		<input type="checkbox" class="form-check-input" :id="id" :checked="value === true" @change="updateValue">
		<label class="form-check-label" :for="id"><slot></slot></label>
		<div v-if="hasError" class="error">{{error}}</div>
	</div>
</template>

<script>
	let uuid = 0;
	export default {
		name: "FieldCheckbox",
		data () {
			return {
				id: null
			}
		},
		computed: {
			hasError(){
				return typeof this.error !== 'undefined' && this.error !== '';
			},
			classes(){
				return [
					this.hasError ? 'has-error' : ''
				]
			},
		},
		methods: {
			updateValue()
			{
				this.$emit('input', !this.value)
			},
		},
		props:{
			label: String,
			value: Boolean,
			error: String
		},
		created() {
			this.id = 'c-checkbox-'+uuid.toString();
			uuid += 1;
		}
	};
</script>

<style lang="scss" scoped>
	.field-checkbox{
		.error{
			color: $danger;
			padding-left: 0px;
			font-size: 0.625rem;
		}
	}
</style>
